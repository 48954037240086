import React from 'react'

const Footer = () => {
  return (
    <div className='h-[600px] w-full px-44 py-32 bg-gray-900'>
        
    </div>
  )
}

export default Footer