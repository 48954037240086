import React from "react";
import Navbar from "./Navbar"
import Fade from "react-reveal/Fade"
import { TypeAnimation } from "react-type-animation"
import HeroGal from "../assets/HeroSvg.svg"
import GraphImage from "../assets/graph.png"
import ClassImage from "../assets/class.jpg"
import GalsImage from "../assets/girls.jpg"
import SaloonImage from "../assets/saloon.jpg"

const Hero = () => {
  return (
    <div className="w-full h-[1000px] bg-gradient-to-r from-green-50 to-green-100 lg:px-20 xl:px-24 3xl:px-44 pt-8">
      <Navbar />
      <div className="h-[1000px] w-full grid grid-cols-1 lg:grid-cols-2 gap-10 py-20 lg:py-44">
        <Fade left>
          <div>
            <div className="flex flex-col lg:flex-row items-center space-x-2 mb-6">
              <div className="text-4xl xl:text-[60px] 2xl:text-[60px] font-bold text-start text-green-900">
                Super{" "}
              </div>
              <TypeAnimation
                sequence={[
                  "Convenient way",
                  2000,
                  "Intelligent way",
                  2000,
                  "Seamless way",
                  2000,
                ]}
                className="text-4xl xl:text-[60px] 2xl:text-[60px] font-bold text-start text-yellow-300"
                speed={50}
                repeat={Infinity}
              />
            </div>
            <div className="text-3xl xl:text-6xl font-bold text-center md:text-start text-green-900 mb-6">
              to make online & offline
            </div>
            <div className="text-3xl xl:text-6xl font-bold text-center md:text-start text-green-900 mb-10">
              Payments possible
            </div>
            <p className="px-6 md:px-0 text-lg font-light mb-20 text-center md:text-justify">
              Whether making international payments, receiving funds, managing
              your digital business, or accessing capital, GPay account opens
              your business up to the world of seamless transaction.
            </p>
            <div className="w-[180px] flex flex-col items-center justify-center">
              <div className="px-6 py-2 text-sm text-center text-gray-50 bg-green-900 border border-green-900 rounded-md font-bold hover:bg-green-700 hover:cursor-pointer">
                Create Account
              </div>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="relative hidden lg:block">
            <div className="absolute -top-20 z-20">
              <img src={HeroGal} className="h-[700px] rounded-2xl" alt="GPay" />
            </div>
            <div className="rounded-xl w-[300px] h-[150px] bg-white absolute -top-10 right-8 z-30 shadow p-2">
              <div className="rounded-xl w-full h-full border border-green-200 p-3">
                <div className="text-sm font-light text-green-900 mb-4">
                  Active payment requests
                </div>
                <div className="text-[10px] text-green-900 mb-2">
                  You requested ZMW400k for disbursement
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <div className="flex flex-row items-center">
                    <div className="h-6 relative">
                      <div className="h-6 w-6 rounded-full border border-gray-200 bg-green-100 absolute">
                        <img src={SaloonImage} className="h-6 w-6 rounded-full object-cover" alt="gpay_zambia"/>
                      </div>
                      <div className="h-6 w-6 rounded-full border border-gray-200 bg-green-100 absolute left-2">
                        <img src={GalsImage} className="h-6 w-6 rounded-full object-cover" alt="gpay_zambia"/>
                      </div>
                      <div className="h-6 w-6 rounded-full border border-gray-200 bg-green-100 absolute left-5">
                        <img src={ClassImage} className="h-6 w-6 rounded-full object-cover" alt="gpay_zambia"/>
                      </div>
                    </div>
                  </div>
                  <div className="w-10"></div>
                  <div className="w-2 h-2 rounded-full bg-yellow-600 bg-opacity-50"></div>
                  <div className="text-[9px]">283k transactions</div>
                  <div className="w-2 h-2 rounded-full bg-yellow-600 bg-opacity-50"></div>
                  <div className="w-2"></div>
                </div>
                <div className="flex flex-row items-center mt-3">
                  <div className="h-1 w-1/2 bg-green-500"></div>
                  <div className="h-1 w-full bg-yellow-400"></div>
                </div>
              </div>
            </div>
            <div className="rounded-xl w-[400px] h-[250px] bg-white absolute bottom-12 -left-24 z-30 shadow p-3">
              <div className="rounded-xl w-full h-full border border-green-200 p-6">
                <div>
                  <div className="text-sm font-bold text-green-800">Balance</div>
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-[10px] text-gray-400">Composite Account {">"}</div>
                    <div className="rounded-full w-[70px] px-1 border border-green-200 text-[10px] text-center text-green-600"> + Deposit</div>
                  </div>
                 <img src={GraphImage} alt="gpay_zimbabwe"/>
                </div>
              </div>
            </div>
            <div className="bg-gradient-to-tr from-yellow-500 to-green-900 bg-opacity-25 rounded-3xl rotate-45 h-[200px] w-[200px] absolute right-0 top-10 z-10"></div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Hero;
