import PrivateRoute from './routing/PrivateRoute';
import  { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//routing
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PasswordResetPage from "./pages/PasswordResetPage";

import LandingPage from "./pages/LandingPage";
import ReceiptPage from "./pages/ReceiptPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import VerifyEmail from "./pages/VerifyEmail";
import DashboardPage from "./pages/DashboardPage";

import ApiOverviewPage from './pages/ApiOverviewPage';
import ApiDocumentationPage from './pages/ApiDocumentationPage';
import PricingPage from './pages/PricingPage';
import SupportPage from './pages/SupportPage';
import FreelancerPage from './pages/FreelancerPage';
import CompanyPage from './pages/CompanyPage';
import NGOSocietyPage from './pages/NGOSocietyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PaymentsPage from './pages/PaymentsPage';
import TransfersPage from './pages/TransfersPage';
import CustomersPage from './pages/CustomersPage';
import WebhooksPage from './pages/WebhooksPage';
import ApiPage from './pages/ApiPage';
import BusinessSettingsPage from './pages/BusinessSettingsPage';
import NotFound from './pages/NotFound';
import ReportsPage from './pages/ReportsPage';
import InvoicesPage from './pages/InvoicesPage';
import InstantMobileMoneyPage from './pages/InstantMobileMoney';
import SamplePayCheckout from './pages/SamplePayCheckout'
import CustomerPage from './pages/CustomerPage';
import PaymentCheckoutLink from './pages/PaymentCheckoutLink';
import DownloadMobileApp from './pages/DownloadMobileApp';


function App() {

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage}/>
          <Route exact path="/r/:shortUrl" component={ReceiptPage}/>
          <Route exact path="/pay/:paymentCheckout" component={PaymentCheckoutLink}/>
          <Route exact path="/app" component={DownloadMobileApp}/>
          <Route exact path="/sampling" component={SamplePayCheckout}/>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/verify-email/:verifyToken" component={VerifyEmail} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
          <Route exact path="/passwordreset/:resetToken" component={PasswordResetPage} />
          <Route exact path="/api-overview" component={ApiOverviewPage} />
          <Route exact path="/api-documentation" component={ApiDocumentationPage} />
          <Route exact path="/pricing" component={PricingPage} />
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/register/freelancer" component={FreelancerPage} />
          <Route exact path="/register/company" component={CompanyPage} />
          <Route exact path="/register/ngo" component={NGOSocietyPage} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
          {/* <Route exact path="/*" component={NotFound} /> */}
          <PrivateRoute exact path="/dashboard" component={DashboardPage}/>
          <PrivateRoute exact path="/payments" component={PaymentsPage}/>
          <PrivateRoute exact path="/transfers" component={TransfersPage}/>
          <PrivateRoute exact path="/customers" component={CustomersPage}/>
          <PrivateRoute exact path="/customer/:customer_id" component={CustomerPage}/>
          <PrivateRoute exact path="/webhooks" component={WebhooksPage}/>
          <PrivateRoute exact path="/reports" component={ReportsPage}/>
          <PrivateRoute exact path="/invoices" component={InvoicesPage}/>
          <PrivateRoute exact path="/instant/pay" component={InstantMobileMoneyPage}/>
          <PrivateRoute exact path="/api" component={ApiPage}/>
          <PrivateRoute exact path="/business-settings" component={BusinessSettingsPage}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
