import React from 'react'
import Navbar from '../components/Navbar'

const SupportPage = () => {
  return (
    <div className="w-full h-[1000px] bg-gradient-to-r from-green-50 to-green-100 lg:px-20 xl:px-24 3xl:px-44 pt-8">
    <Navbar/>
  </div>
  )
}

export default SupportPage