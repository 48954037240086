import {useEffect} from 'react'
import Hero from '../components/Hero'
import HowItWorks from "../components/HowItWorks";
import SectionHeader from "../components/SectionHeader";
import Spacer from "../components/Spacer";
import Footer from "../components/Footer";
import OurPartners from '../components/OurPartners';
import { Reviews } from '../components/Reviews';
import { FooterBottom } from '../components/FooterBottom';
import { PrimaryFeatures } from '../components/PrimaryFeatures';
import { useSelector } from 'react-redux'


const LandingPage = ({history}) => {
  const {user} = useSelector(state =>state.auth);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
        history.push("/dashboard");
        console.log(user);
    }
}, [user]);
  return (
    <div>
        <Hero/>
        <OurPartners/>
        <Spacer/>
        <PrimaryFeatures/>

        <Spacer/>
        <Reviews/>
        <Footer/>
        <FooterBottom/>
    </div>
  )
}

export default LandingPage