import React from 'react'

const OurPartners = () => {
  return (
    <div className='py-20'>
        <div className='text-green-800 font-bold text-4xl text-center'>Our Partners</div>
        <div className='flex flex-row items-center justify-center space-x-16'>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default OurPartners