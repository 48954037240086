import React from 'react'
import Fade from 'react-reveal/Fade'
import MoneyIcon from '../assets/money.png'
import ShoppingIcon from '../assets/shopping.png'
import ZescoIcon from '../assets/zesco.png'
import UssdIcon from '../assets/ussd.png'
import LinkIcon from '../assets/link.png'

const ProductsMenu = () => {
  return (
    <>
    <Fade top>
    <div className='w-[320px] py-6 rounded-md shadow-md bg-green-50 z-50 absolute top-[30px] -left-8'>
        <div className='flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer'>
            <div className='w-10 h-10 rounded-md bg-green-900 flex items-center justify-center'>
                <img src={MoneyIcon} alt='gpay' className='h-5'/>
            </div>
            <div>
                <div className='text-sm font-medium text-green-800 text-start'>Payment Gateway</div>
                <div className='text-xs font-light text-green-800 text-start'>Accept payment easily</div>
            </div>
        </div>
        <div className='flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer'>
            <div className='w-10 h-10 rounded-md bg-green-900 flex items-center justify-center'>
                <img src={LinkIcon} alt='gpay' className='h-5'/>
            </div>
            <div>
                <div className='text-sm font-medium text-green-800 text-start'>Payment Links</div>
                <div className='text-xs font-light text-green-800 text-start'>Send our quick links & get paid</div>
            </div>
        </div>
        <div className='flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer'>
            <div className='w-10 h-10 rounded-md bg-green-900 flex items-center justify-center'>
                <img src={UssdIcon} alt='gpay' className='h-5'/>
            </div>
            <div>
                <div className='text-sm font-medium text-green-800 text-start'>GPay USSD</div>
                <div className='text-xs font-light text-green-800 text-start'>Accept payment via merchant code</div>
            </div>
        </div>
        <div className='flex flex-row items-center space-x-3 w-full hover:bg-green-100 px-6 py-3 hover:cursor-pointer mb-6'>
            <div className='w-10 h-10 rounded-md bg-green-900 flex items-center justify-center'>
                <img src={ZescoIcon} alt='gpay' className='h-5'/>
            </div>
            <div>
                <div className='text-sm font-medium text-green-800 text-start'>GPay Value Added Services</div>
                <div className='text-xs font-light text-green-800 text-start'>Airtime,Bundles,ZESCO e.t.c</div>
            </div>
        </div>
        <div className='flex flex-row items-center space-x-3 w-full hover:bg-green-100 px-6 py-3 hover:cursor-pointer'>
            <div className='w-10 h-10 rounded-md bg-green-900 flex items-center justify-center'>
                <img src={ShoppingIcon} alt='gpay' className='h-5'/>
            </div>
            <div>
                <div className='text-sm font-medium text-green-800 text-start'>Ecommerce</div>
                <div className='text-xs font-light text-green-800 text-start'>Start selling products online</div>
            </div>
        </div>
    </div>
    </Fade>
    </>
  )
}

export default ProductsMenu